import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ColumnAlternatingStyled from '../components/columnAlternatingStyled'
import BlogCardItems from '../components/cardBlog'
import BlogTags from '../components/blogTags'
import BlogItem from '../components/blogItem'
import moment from 'moment'


const TagRoute = (props) =>  {
  const { location } = props
  const posts = props.data.allMarkdownRemark.edges
  const heroImage = props.data.heroImage

  const { tag } = props.pageContext
  const { tagPath } = props.pageContext
  const { totalCount } = props.data.allMarkdownRemark
  const tagHeader = `${tag} (${totalCount})`
  const { currentPage, numTagPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numTagPages
  const prevPage = (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const getPaginationPages = numTagPages => {
    let content = []
    for (let i = 1; i <= numTagPages; i++) {
      content.push(<option key={i} value={i} aria-label={`Go to page ${i}`} aria-current="false" >{i}</option>)
    }
    return content
  }

  const changePage = e => {
    navigate(
      e.target.value && e.target.value !== "1"
        ? `${tagPath}${e.target.value}/`
        : `${tagPath}`
    )
  }

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={`UNICOM Government ${tag} blogs` + ((currentPage > 1) ? ' (' + currentPage + ')' : '')}
          description={`Insightful ${tag} blogs for Government agencies` + ((currentPage > 1) ? ': page ' + currentPage : '')}
          url={props.data.site.siteMetadata.siteUrl + location.pathname} 
          image={props.data.site.siteMetadata.siteUrl + heroImage.childImageSharp.gatsbyImageData.src}          
        />

        <Hero
          class="intro-50"
          image={ heroImage.childImageSharp.gatsbyImageData }
          title="UNICOM Government blog"
          subtitle="Technology updates and insights."
          type="blog"
        />

        <main>
        <section id="main" className="bg-white pt-4">
            <MDBContainer>
              <BlogTags btnActive={tag}/>

              <MDBRow className="pb-3">
                <MDBCol md="12" className="pt-3" >
                  <p className="font-alt font-w-300 letter-spacing-1 text-medium"><Link to="/blog/" className="effect">Blog</Link> / <Link to="/blog/tags/" className="effect">Tags</Link> / {tagHeader}</p>
                </MDBCol>
              </MDBRow>

              <>
              {currentPage === 1 ?
                // Render page 1 with cards for first 3 items and list of remaining items for page 1
                <div className="pt-5">
                  {posts.slice(0, 1).map(({ node }, index) => (
                    <ColumnAlternatingStyled
                      key={index}
                      textCol="7"
                      imageCol="5"
                      title={node.frontmatter.title}
                      subtitle={node.frontmatter.subtitle}
                      subtitlecolour={null}
                      description={node.frontmatter.description}
                      image={node.frontmatter.imageFeatured ? node.frontmatter.imageFeatured.childImageSharp.gatsbyImageData : node.frontmatter.image.childImageSharp.gatsbyImageData}
                      alt={node.frontmatter.alttext}
                      placement="right"
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={node.fields.slug}
                      date={moment(node.frontmatter.date).format('YYYY/MM/DD')} 
                      author={node.frontmatter.author}
                      readtime={node.frontmatter.readtime}   
                      />
                  ))}
                  
                  <MDBRow>
                    {posts.slice(1,3).map(({ node }, index) => (
                      <MDBCol key={index} md="12" lg="6" className="d-flex">
                        <BlogCardItems
                          key={index}
                          collg="6"
                          colmd="6"
                          link={node.fields.slug}
                          image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                          alt={node.frontmatter.alttext}
                          title={node.frontmatter.title}
                          description={node.frontmatter.description.length <= 160 ? node.frontmatter.description : node.frontmatter.description.substr(0, 160) + '...'}
                          date={node.frontmatter.date}
                          author={node.frontmatter.author}
                          readtime={node.frontmatter.readtime}
                        />
                      </MDBCol>
                    ))}
                  </MDBRow>

                  {posts.slice(3).map(({ node }, index) => (
                    <BlogItem data={node} key={index}/>
                  ))}
                </div>

                : 
                // return full page of items in list form when page != 1
                <>
                  {posts.map(({ node }, index) => (
                    <BlogItem data={node} key={index} />
                  ))}
                </>

              }
              </>


              <MDBRow className="pagination pt-4 mt-5">
                {!isFirst && (
                  <MDBCol className="text-left">
                    <Link to={currentPage === 2 ? `${tagPath}` : `${tagPath}${prevPage}/`} rel="prev" className="linkedin">
                      ← Newer posts
                    </Link>
                  </MDBCol>
                )}
                {!isLast && (
                  <MDBCol className="text-right">
                    <Link to={`${tagPath}${nextPage}/`} rel="next" className="linkedin">
                      Older posts →
                    </Link>
                  </MDBCol>
                )}
              </MDBRow>

              <MDBRow className="pt-3">
                <MDBCol className="text-right">
                  <span>Showing page </span> 
                  <select 
                    aria-label="Pagination dropdown"
                    value={currentPage === 1 ? `` : currentPage.toString()}
                    onChange={changePage}
                  >
                    {getPaginationPages(numTagPages)}
                  </select>
                  <span> of {numTagPages}</span>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        </main>
      </div>
    </Layout>
  )
}
export default TagRoute

export const tagPageQuery = graphql`
  query BlogTagItems($tag: String, $skip: Int!, $limit: Int!) {
    site {
        siteMetadata {
          title
          siteUrl
        }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$tag] } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip 
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description 
            date
            author
            readtime
            image {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90)
              }
            }
            alttext
            imageFeatured {
              childImageSharp {
                gatsbyImageData(width: 900, quality: 90)
              }
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "unicom-government-blog-banner" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }  
  }
`